import request from '@/utils/request'


// 查询咨询列表
export function listConsult(query) {
  return request({
    url: '/consult/consult/list',
    method: 'get',
    params: query
  })
}

// 查询咨询分页
export function pageConsult(query) {
  return request({
    url: '/consult/consult/page',
    method: 'get',
    params: query
  })
}

// 查询咨询详细
export function getConsult(data) {
  return request({
    url: '/consult/consult/detail',
    method: 'get',
    params: data
  })
}

// 新增咨询
export function addConsult(data) {
  return request({
    url: '/consult/consult/add',
    method: 'post',
    data: data
  })
}

// 修改咨询
export function updateConsult(data) {
  return request({
    url: '/consult/consult/edit',
    method: 'post',
    data: data
  })
}

// 删除咨询
export function delConsult(data) {
  return request({
    url: '/consult/consult/delete',
    method: 'post',
    data: data
  })
}
